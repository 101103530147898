// SolitaireGamePage.jsx
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Column from "../components/Column";
import Foundation from "../components/Foundation";
import Deck from "../components/Deck";
import Discard from "../components/Discard";
import Header from "../components/Header";
import CustomDragLayer from "../components/CustomDragLayer";
import Icon from "../components/Icon";
import icons from "../assets/icons";
import VictoryModal from "../components/VictoryModal";
import AlertModal from '../components/AlertModal';
import TutorialModal from "../components/TutorialModal";

const SolitaireGamePage = observer(() => {
  const { columns, moveCards, history, undo, foundations, isAutoCompleting } = gameStore;
  const [isVictoryModalOpen, setIsVictoryModalOpen] = useState(false);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);

  // Try to load saved game on component mount
  useEffect(() => {
    if (!gameStore.currentGame.isStarted) {
      const loaded = gameStore.loadGameFromStorage();
      if (loaded) {
        gameStore.currentGame.isStarted = true;
      }
    }
  }, []);

  useEffect(() => {
    if (gameStore.isNewProfile) {
      setIsTutorialOpen(true);
      gameStore.setProfileTutorialShown();
    }
  }, [gameStore.isNewProfile]);

  useEffect(() => {
    if (gameStore.currentGame.isStarted) {
      gameStore.saveGameToStorage();
    }
  }, [
    gameStore.deck,
    gameStore.columns,
    gameStore.discard,
    gameStore.foundations,
    gameStore.currentGame,
    gameStore.gameTime
  ]);

  // Проверяем победу при каждом изменении foundations
  useEffect(() => {
    if (gameStore.isVictory) {
      gameStore.currentGame.victoryTime = gameStore.gameTime;
      gameStore.currentGame.isStarted = false;
      gameStore.clearSavedGame();
      gameStore.sendWinData(gameStore.currentGame.score)
        .then(response => {
          if (response.data.profile) {
            gameStore.updateProfile(response.data.profile);
          }
          setIsVictoryModalOpen(true);
        })
        .catch(error => {
          setIsVictoryModalOpen(true);
        });
    }
  }, [gameStore.isVictory]);

  useEffect(() => {
    if (gameStore.noMovesDetected) {
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        gameStore.noMovesDetected = false;
      }, 4000);
    }
  }, [gameStore.noMovesDetected]);

  const handleUndo = () => {
    undo();
  };

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <div className="relative flex flex-col min-h-screen text-[#F6F3FC] px-2 pt-16 max-w-7xl mx-auto p-4">
        <Header />
        {/* Модальное окно победы */}
        <VictoryModal 
          isOpen={isVictoryModalOpen} 
          onClose={() => setIsVictoryModalOpen(false)}
        />
        <AlertModal
          message="Нет доступных ходов!"
          isVisible={isAlertVisible}
          setAlertVisible={setAlertVisible}
        />
        <TutorialModal isOpen={isTutorialOpen} onClose={() => setIsTutorialOpen(false)} />

        {/* Верхняя строка: Foundations + Deck + Discard */}
        <div className="flex justify-center gap-0.5 mt-4">
          <Foundation suit="hearts" />
          <Foundation suit="diamonds" />
          <Foundation suit="clubs" />
          <Foundation suit="spades" />
          <div
            className="relative
          w-20 sm:w-24 md:w-28 lg:w-32 h-28 sm:h-32 md:h-36 lg:h-40
          "
            style={{
              width: "50px",
              height: "75px",
            }}
          ></div>
          <Discard />
          <Deck />
        </div>

        {/* Основные колонки */}
        <div className="flex flex-1 mt-6 w-full overflow-auto justify-center items-start">
          {columns.map((column, columnIndex) => (
            <Column
              key={`column-${columnIndex}`}
              cards={column}
              columnIndex={columnIndex}
              moveCards={moveCards}
            />
          ))}
        </div>

        {/* Кнопки управления */}
        <div className="sticky bottom-6 w-full flex justify-center">
          <div className="flex items-center">
            <button
              onClick={() => setIsTutorialOpen(true)}
              className="flex flex-col items-center"
            >
              <Icon
                icon={icons.Help}
                className="w-8 h-8 fill-[#F6F3FC] opacity-80"
              />
              <span className="uppercase text-[#F6F3FC] text-xl font-bold">
                Как играть
              </span>
            </button>

            {history.length > 0 && !gameStore.isAutoCompleting && (
              <>
                <div className="w-8" />
                <button
                  onClick={handleUndo}
                  className="flex flex-col items-center"
                >
                  <Icon
                    icon={icons.Reload}
                    className="w-8 h-8 fill-[#F6F3FC] opacity-80"
                  />
                  <span className="uppercase text-[#F6F3FC] text-xl font-bold">
                    Отмена хода
                  </span>
                </button>
              </>
            )}
          </div>
        </div>

        {/* Слой для отображения перетаскиваемых карт */}
        <CustomDragLayer />
      </div>
    </DndProvider>
  );
});

export default SolitaireGamePage;
