import React, { useRef } from 'react';
import Modal from './Modal';

const InviteLinkModal = ({ isOpen, onClose, inviteLink }) => {
  const linkRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Пригласить друзей"
      hasCloseButton={true}
    >
      <div className="px-6 py-4">
        <div className="mb-4">
          <p className="text-[#F6F3FC] text-sm mb-2">
            Поделитесь этой ссылкой с друзьями:
          </p>
          <div className="bg-[#4A2B7E] rounded-lg p-3">
            <textarea
              ref={linkRef}
              readOnly
              value={inviteLink}
              className="w-full bg-transparent text-[#F6F3FC] text-sm outline-none resize-none"
              onClick={(e) => e.target.select()}
              style={{
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap',
                height: 'auto',
                minHeight: '2.5rem',
                overflow: 'hidden'
              }}
              rows={6}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteLinkModal;
