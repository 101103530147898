import React from "react";

const Button = ({ icon: Icon, text, className = "", iconClassName = "", textClassName = "", onClick= () => alert("нет события"), style={}, ...props }) => {
  return (
    <button
      className={`flex items-center justify-center gap-2 rounded-lg font-bold text-base shadow-md text-[#623FA5] ${className}`}
      style={style}
      {...props}
      onClick={onClick}
    >
      {text && <span className={`font-bold text-xl uppercase ${textClassName}`}>{text}</span>}
      {Icon && <Icon className={`w-6 h-6 ml-1 ${iconClassName}`} />}
    </button>
  );
};

export default Button;