import React from "react";
import { Link } from "react-router-dom";

const ButtonLink = ({
  to,
  text,
  icon: Icon,
  afterText = "", // Дополнительный текст после иконки
  className = "",
  style = {},
  iconClassName = "",
  textClassName = "",
  afterTextClassName = "", // Стили для текста после иконки
  ...props
}) => {
  return (
    <Link
      to={to}
      className={`flex items-center justify-center gap-2 rounded-lg font-bold text-base ${className}`}
      style={style}
      {...props}
    >
      {text && <span className={`font-bold text-2xl uppercase ${textClassName}`}>{text}</span>}
      {Icon && <Icon className={`w-6 h-6 ml-1 ${iconClassName}`} />}
      {afterText && (
        <span className={`font-bold text-xl ${afterTextClassName}`}>{afterText}</span>
      )}
    </Link>
  );
};

export default ButtonLink;
