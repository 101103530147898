import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import gameStore from '../../store/store';
import { API_BASE_URL, API_ENDPOINTS } from '../../config';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const checkAuth = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        const initData = tg.initData || '';
        const initDataUnsafe = tg.initDataUnsafe || {};
        axios.post(`${API_BASE_URL}${API_ENDPOINTS.AUTH}`, { initData, initDataUnsafe })
          .then(response => {
            const data = response.data;
            const status = response.status;
            const token = data.token;
            if (status === 200 && data.success) {
              gameStore.updateStatus(true);
              gameStore.updateToken(token);
              gameStore.updateDataUnsafe(initDataUnsafe);
              // Update profile data if available
              if (data.profile) {
                gameStore.updateProfile(data.profile);
              }
            } else {
              gameStore.updateStatus(false);
            }
          }).catch(error => {
            gameStore.updateStatus(false);
          }).finally(() => {
            gameStore.checkInviteUrl();
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const value = {
    loading,
    isAuthenticated: gameStore.loginStatus,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
