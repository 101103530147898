import React from "react";
import Icon from "./Icon";

const TicketReward = ({ text, icon, className = "", iconClassName = "", textClassName = "", style = {}, iconStyle = {} }) => {
  return (
    <div className={`flex items-center gap-1 justify-center ${className}`}>
      <span
        className={`text-[#F6F3FC] font-bold text-lg ${textClassName}`}
        style={{ textShadow: "0px 4px 0px #623FA5", ...style }}
      >
        {text}
      </span>
      <Icon
        icon={icon}
        activeIcon={icon}
        className={`w-6 h-6 fill-[#F6F3FC] ${iconClassName}`}
        style={{
          filter: "drop-shadow(0px 4px 0px #623FA5)",
          ...iconStyle,
        }}
      />
    </div>
  );
};

export default TicketReward;
