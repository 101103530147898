export const API_BASE_URL = 'https://solitaire.jam.style/api/webapp'

// API endpoints
export const API_ENDPOINTS = {
  AUTH: '/auth',
  DECK: '/game/solo/start',
  WIN: '/game/solo/win',
  MOVE: '/game/solo/move',
  FRIEND_INVITE: '/user/invite/link?type=friend',
  GAME_INVITE: '/user/invite/link?type=duel',
  FRIEND_ACCESS: '/user/invite/access',
  GAME_ACCESS: '/user/invite/access',
  PAYMENT_CREATE_INVOICE: '/user/invoice/link',
  FRIENDS: '/user/friends',
  LEADERBOARD: '/user/leaderboard',
  PROFILE: '/user/profile',
  GET_UPDATES: '/user/notifications',
}
