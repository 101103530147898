import React, { useEffect } from "react";
import BottomNavigation from "../components/BottomNavigation";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Icon from "../components/Icon";
import icons from "../assets/icons";
import opponentImg from "../assets/img/opponent.png";

const LeaderboardPage = observer(() => {
  const { leaderboard, myScore, myName, myPosition } = gameStore;

  useEffect(() => {
    gameStore.fetchLeaderboard();
  }, []);

  return (
    <div>
      <div className="h-screen max-w-md mx-auto pb-24 pt-5">
        {/* Заголовок */}
        <div
          className="h-full flex flex-col text-[#F6F3FC] rounded-t-2xl w-11/12 mx-auto"
          style={{
            background: "#623FA5A8",
          }}
        >
          <div
            className="w-full text-center bg-[#623FA5] p-3.5 rounded-t-2xl"
            style={{
              border: "2px solid #623FA5",
            }}
          >
            <h1 className="text-xl font-bold uppercase tracking-wide">
              Рейтинг игроков
            </h1>
          </div>

          {/* Список лидеров с прокруткой */}
          <div
            className="flex-1 w-full mx-auto overflow-y-auto pt-4 -mb-0.5 px-1.5"
            style={{
              border: "2px solid #623FA5"
            }}
          >
            {leaderboard
              .filter((player) => player.position > 0)
              .map((player, index) => (
                <div
                  key={index}
                  className="flex items-center rounded-lg mb-2 overflow-hidden"
                  style={{
                    backgroundColor: player.iam ? "#E3FFEE" : "#F6F3FC",
                  }}
                >
                  {/* Левая плашка */}
                  <div
                    className={`w-12 h-12 flex items-center justify-center text-[#623FA5] font-bold ${player.position === 1
                      ? "bg-[#FFD57B]"
                      : player.position === 2
                        ? "bg-[#FFA97B]"
                        : player.position === 3
                          ? "bg-[#D9D7D7]"
                          : "bg-[#D9B3FF]"
                      }`}
                  >
                    {player.position}
                  </div>

                  {/* Остальная информация */}
                  <div className="flex-1 flex justify-between items-center text-[#623FA5] pr-3 pl-2">
                    <div className="flex items-center gap-2">
                      <div
                        className="w-8 h-8 rounded-full flex items-center justify-center border"
                        style={{
                          backgroundColor: "#D9B3FF",
                          border: "1px solid #F6F3FC",
                        }}
                      >
                        <img
                          src={`${player.avatar || opponentImg}`}
                          alt={`${player.name}'s avatar`}
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                      <span className={`font-bold ${player.iam ? "text-[#28A745]" : ""}`}>{player.name}</span>
                    </div>
                    <div className="flex items-center font-bold text-xl">
                      <Icon
                        icon={icons.Stats}
                        activeIcon={icons.Ticket}
                        className="w-5 h-5 fill-[#623FA5] mr-1"
                      />
                      <span>{player.score}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Мой рейтинг */}
          <div
            className="bg-[#C9F456] text-[#247A0A] p-3 shadow-lg -mx-1.5 -mb-1.5 rounded-xl sticky bottom-[90px]"
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <div className="text-xl text-[#247A0A] font-bold">
                  {myPosition > 0 && myPosition}
                  {myPosition === 0 && "10+"}
                </div>
                <span className="font-medium">{myName}</span>
              </div>
              <div className="flex items-center font-bold text-xl">
                <Icon
                  icon={icons.Stats}
                  activeIcon={icons.Ticket}
                  className="w-5 h-5 fill-[#247A0A] mr-1"
                />
                <span>{myScore}</span>
              </div>
            </div>
          </div>

          {/* Навигация */}
        </div>
        <BottomNavigation />
      </div>
    </div>
  );
});

export default LeaderboardPage;
