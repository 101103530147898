import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import gameStore from "../../store/store";

const ErrorRoute = observer(({ children }) => {
  const { error } = gameStore;

  if (error) {
    return <Navigate to="/error" />;
  }

  return children;
});

export default ErrorRoute;
