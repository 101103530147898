import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./features/MainApp";
import { AuthProvider } from "./components/auth/AuthContext";
import Alert from "./components/Alert";

const App = () => {
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand(); // Запрос на максимальную высоту
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Alert />
        <MainApp /> {/* Новый компонент, отвечающий за фон */}
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
