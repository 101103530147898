import React from "react";
import gameStore from "../store/store";
import Button from "../components/Button";

const ErrorPage = () => {
  const handleClose = () => {
    window.Telegram.WebApp.close()
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-col gap-6 items-center justify-center text-[#F6F3FC] uppercase font-bold h-screen w-11/12 max-w-md mx-auto text-2xl text-center">
        <span>{gameStore.error || "Что то пошло не так..."}</span>
        <Button
          text="Закрыть игру"
          onClick={handleClose}
          className="bg-[#F6F3FC] text-[#6641A9] py-2 px-6 mx-12"
          style={{
            boxShadow: "0px 4px 0px 0px #623FA5",
          }}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
