// Deck.jsx
import React from "react";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Card from "./Card";
import { FaSyncAlt } from "react-icons/fa";

const Deck = observer(() => {
  const { deck } = gameStore;

  const handleClick = () => {
    gameStore.drawCard();
  };

  return (
    <div
      className="relative rounded-lg shadow-lg flex items-center justify-center 
                 w-20 sm:w-24 md:w-28 lg:w-32 h-28 sm:h-32 md:h-36 lg:h-40
                 transition-colors duration-200
                 cursor-pointer
                 "
      style={{
        width: "50px",
        height: "75px",
        backgroundColor: "#623FA57D",
        border: deck.length > 0 ? "none" : "1px solid #623FA5",
      }}
      onClick={handleClick}
    >
      {deck.length > 0 ? (
        <div className="relative w-full h-full">
          {deck.slice(-5).map((card, index) => ( // Отображаем последние 5 карт для оптимальной производительности
            <Card
              key={card.id}
              card={card}
              columnIndex={-3} // Специальный индекс для deck
              isDraggable={false}
              index={index}
              style={{
                position: 'absolute',
                top: `${index * 0.7}px`,
                left: `${index * 0.7}px`,
                zIndex: deck.length - index, // Верхняя карта поверх
              }}
            />
          ))}
          <span className="absolute -top-4 right-1 text-xs text-[#623FA5]">
            {deck.length}
          </span>
        </div>
      ) : (
        <FaSyncAlt className="text-[#623FA57D] text-2xl" />
      )}
    </div>
  );
});

export default Deck;
