import React from 'react';
import { observer } from 'mobx-react-lite';
import gameStore from '../store/store';

const AlertModal = observer(({ message, isVisible, setAlertVisible }) => {
  const handleNewGame = () => {
    gameStore.startNewGame();
    setAlertVisible(false);
  };

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 bg-[#623FA5] text-white text-center py-6 transition-all duration-300 ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
      style={{ zIndex: 1000 }}
    >
      <div className="flex items-center justify-center gap-4">
        <span>{message}</span>
        {gameStore.tickets > 0 && (
          <button 
            onClick={handleNewGame}
            className="px-4 py-2 bg-white text-blue-500 rounded-lg hover:bg-blue-50 transition-colors"
          >
            Новая игра
          </button>
        )}
      </div>
    </div>
  );
});

export default AlertModal;
