// CustomDragLayer.jsx
import React from "react";
import { useDragLayer } from "react-dnd";
import Card from "./Card";

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 1000,
};

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const CustomDragLayer = () => {
  const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  if (!isDragging || !currentOffset || !item.cards) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>
        {item.cards.map((card, index) => (
          <Card
            key={card.id}
            card={card}
            columnIndex={item.fromColumnIndex}
            isDraggable={false}
            style={{
              position: "absolute",
              top: index * 26, // Смещение по вертикали для эффекта стека
              left: index * 5, // Добавлено небольшое смещение по горизонтали
              zIndex: item.cards.length + index, // Верхние карты отображаются сверху
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Добавление тени для лучшего визуального эффекта
              transform: `rotate(${index * 2}deg)`, // Добавление небольшого вращения
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomDragLayer;
