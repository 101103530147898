// Foundation.jsx
import React from "react";
import { useDrop } from "react-dnd";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Card from "./Card";
import icons from "../assets/icons";

const Foundation = observer(({ suit }) => {
  const foundation = gameStore.foundations[suit];
  const topCard = foundation[foundation.length - 1];

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: "CARD",
    canDrop: (item) => {
      const movingCard = item.cards[0];
      if (movingCard.suit !== suit) return false;

      if (foundation.length === 0) {
        return movingCard.value === 1; // Только туз можно положить в пустую стопку
      }

      const lastCard = foundation[foundation.length - 1];
      return movingCard.value === lastCard.value + 1;
    },
    drop: (item) => {
      gameStore.moveCardsToFoundation(item.cards, suit);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Определяем иконку масти
  const Icon = icons[suit.charAt(0).toUpperCase() + suit.slice(1)];


  return (
    <div
      ref={dropRef}
      className={`relative rounded-lg shadow-lg flex items-center justify-center 
                  w-20 sm:w-24 md:w-28 lg:w-32 h-28 sm:h-32 md:h-36 lg:h-40
                  transition-colors duration-200`}
      style={{
        width: "50px",
        height: "75px",
        backgroundColor: isOver && canDrop ? "#C9F456" : "#623FA57D",
        border: topCard ? "none" : "1px solid #623FA5",
      }}
    >
      {topCard ? (
        <Card
          card={topCard}
          columnIndex={-1} // Специальный индекс для foundations
          isDraggable={false}
          index={0}
        />
      ) : (
        <Icon className="w-6 h-6" style={{ fill: "#623FA57D" }} />
      )}
    </div>
  );
});

export default Foundation;
