import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import GamePage from "./GamePage";
import FriendsPage from "./FriendsPage";
import ShopPage from "./ShopPage";
import LeaderboardPage from "./LeaderboardPage";
import SolitaireGamePage from "./SolitaireGamePage";
import ErrorPage from "./ErrorPage";
import PrivateRoute from "../components/auth/PrivateRoute";
import ErrorRoute from "../components/error/ErrorRoute";
import bgImg from "../assets/img/back.png";
import backGame from "../assets/img/backGame.png";

const MainApp = () => {
  const location = useLocation();

  if (location.pathname === "/game") {
    window.Telegram.WebApp.disableVerticalSwipes()
  } else {
    window.Telegram.WebApp.enableVerticalSwipes()
  }

  const currentBgImg = location.pathname === "/game" ? backGame : bgImg;

  return (
    <div
      className="relative flex flex-col min-h-screen w-screen"
      style={{
        background:
          "linear-gradient(180deg, #9141FD 0%, #526FF9 100%), radial-gradient(79.47% 40.22% at 63.43% 34.96%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
        backgroundBlendMode: "overlay",
      }}
    >
      <img
        src={currentBgImg}
        className="absolute left-0 bottom-0 w-full h-full object-cover z-0"
        alt="Background"
      />

      <div className="relative z-10 flex flex-col flex-grow">
        <Routes>
          <Route path="/error" element={<ErrorPage />} />
          <Route
            path="/"
            element={
              <ErrorRoute>
                <PrivateRoute>
                  <GamePage />
                </PrivateRoute>
              </ErrorRoute>
            }
          />
          <Route
            path="/friends"
            element={
              <ErrorRoute>
                <PrivateRoute>
                  <FriendsPage />
                </PrivateRoute>
              </ErrorRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <ErrorRoute>
                <PrivateRoute>
                  <ShopPage />
                </PrivateRoute>
              </ErrorRoute>
            }
          />
          <Route
            path="/leaderboard"
            element={
              <ErrorRoute>
                <PrivateRoute>
                  <LeaderboardPage />
                </PrivateRoute>
              </ErrorRoute>
            }
          />
          <Route
            path="/game"
            element={
              <ErrorRoute>
                <PrivateRoute>
                  <SolitaireGamePage />
                </PrivateRoute>
              </ErrorRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default MainApp;