import React, { createContext, useContext } from "react";
import gameStore from "./store";

const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {
  return (
    <StoreContext.Provider value={gameStore}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
