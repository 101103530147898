// Discard.jsx
import React from "react";
import { useDrop } from "react-dnd";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Card from "./Card";
import { GiCardDraw } from "react-icons/gi";

const Discard = observer(() => {
  const { discardPile } = gameStore;

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: "CARD",
    drop: (item) => {
      gameStore.discardCard(item.cards[0]);
    },
    canDrop: (item) => {
      return true; // Разрешаем сбрасывать любые карты
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={dropRef}
      className={`relative rounded-lg shadow-lg flex items-center justify-center 
                  w-20 sm:w-24 md:w-28 lg:w-32 h-28 sm:h-32 md:h-36 lg:h-40
                  transition-colors duration-200
                  cursor-pointer
                  `}
      style={{
        width: "50px",
        height: "75px",
        border: discardPile.length > 0 ? "none" : "1px solid #623FA5",
        backgroundColor: isOver && canDrop ? "rgba(98, 63, 165, 0.7)" : "rgba(98, 63, 165, 0.49)",
      }}
    >
      {discardPile.length > 0 && (
        <>
          {/* Показываем предпоследнюю карту, если она есть */}
          {discardPile.length > 1 && (
            <Card
              card={discardPile[discardPile.length - 2]}
              columnIndex={-2}
              isDraggable={false}
              index={0}
              style={{
                position: 'absolute',
                zIndex: 0,
              }}
            />
          )}
          {/* Верхняя карта */}
          <Card
            card={discardPile[discardPile.length - 1]}
            columnIndex={-2}
            isDraggable={true}
            cardsToDrag={[discardPile[discardPile.length - 1]]}
            index={1}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 1,
            }}
          />
        </>
      )}
      {discardPile.length === 0 && (
        <GiCardDraw className="text-[#623FA57D] text-3xl" />
      )}
    </div>
  );
});

export default Discard;
