import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from './Modal';

// Импорт изображений
import tutorial1 from '../assets/img/tutorial1.png';
import tutorial2 from '../assets/img/tutorial2.png';
import tutorial3 from '../assets/img/tutorial3.png';
import tutorial41 from '../assets/img/tutorial41.png';
import tutorial42 from '../assets/img/tutorial42.png';
import tutorial5 from '../assets/img/tutorial5.png';

const renderScore = (text) => `<span class="text-[#C9F456]">${text}</span>`;

const tutorialSlides = [
    {
        topText: "Добро пожаловать в Пасьянс «Косынка»! Цель игры — разложить все карты по мастям в порядке возрастания.",
        image: tutorial1
    },
    {
        topText: `На игровом поле карты раскладываются по 7 столбцам. 
Вы можете перемещать карты между столбцами, однако карты должны располагаться в порядке убывания и чередоваться красными и черными.`,
        bottomText: "Например, вы можете положить красную 7 на черную 8.",
        image: tutorial2
    },
    {
        topText: "Вы также можете перемещать целую группу карт. Просто нажмите на одну карту в столбце и перетащите ее в другой столбец.",
        bottomText: "При перемещении из столбца всех открытых карт вам открывается следующая карта в столбце.",
        image: tutorial3
    },
    {
        hasTwoImages: true,
        topText: "Королей можно перемещать на пустое место (если оно есть).",
        bottomText: "В правом верхнем углу расположена колода запаса. Нажав на нее, вы можете открыть ее верхнюю карту и при необходимости переместить на поле. Если колода запаса пуста, то нажмите на нее, чтобы замешать обратно оставшиеся в ней карты.",
        image1: tutorial41,
        image2: tutorial42
    },
    {
        text: `ПОДСЧЕТ ОЧКОВ:
1. Ваш счет начинается с ${renderScore("1000 очков")}
2. Каждое действие стоит ${renderScore("5 очков")}
3. За добавление карты на базу вы получаете ${renderScore("50 очков")}
4. За сбор всей масти на базе ${renderScore("400 очков")}`,
        bottomText: `Вы также можете обратиться к нашему боту за советами и тактиками игры в Пасьянс. 
Или просто узнать интересные факты о Пасьянсе!`,
        image: tutorial5
    }
];

const TutorialModal = ({ isOpen, onClose }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setCurrentSlide(0);
        }
    }, [isOpen]);

    const nextSlide = () => {
        if (currentSlide < tutorialSlides.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const prevSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const renderSlideContent = (slide) => {
        return (
            <div className="px-4 text-left font-bold">
                {(slide.topText || slide.text) && (
                    <div className="text-[#F6F3FC] text-sm mb-2 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: slide.topText || slide.text }}>
                    </div>
                )}

                {slide.image && !slide.hasTwoImages && (
                    <div className="rounded-lg overflow-hidden flex items-center justify-center mb-2">
                        <img 
                            src={slide.image} 
                            alt={`Туториал ${currentSlide + 1}`}
                            className="w-full h-auto"
                        />
                    </div>
                )}

                {slide.hasTwoImages && (
                    <>
                        <div className="rounded-lg overflow-hidden flex items-center justify-center mb-2">
                            <img 
                                src={slide.image1} 
                                alt={`Туториал ${currentSlide + 1}а`}
                                className="w-full h-auto"
                            />
                        </div>
                        {slide.bottomText && (
                            <div className="text-[#F6F3FC] text-sm my-2 whitespace-pre-wrap">
                                {slide.bottomText}
                            </div>
                        )}
                        <div className="rounded-lg overflow-hidden flex items-center justify-center">
                            <img 
                                src={slide.image2} 
                                alt={`Туториал ${currentSlide + 1}б`}
                                className="w-full h-auto"
                            />
                        </div>
                    </>
                )}

                {!slide.hasTwoImages && slide.bottomText && (
                    <div className="text-[#F6F3FC] text-sm mt-2 whitespace-pre-wrap">
                        {slide.bottomText}
                    </div>
                )}
            </div>
        );
    };

    const renderNavigation = () => (
        <div className="flex justify-center items-center gap-8 px-4">
            <button
                onClick={prevSlide}
                disabled={currentSlide === 0}
                className={`${
                    currentSlide === 0
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                }`}
            >
                <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ filter: 'drop-shadow(0px 4px 0px #623FA5)' }}>
                    <path d="M16 6C16 6 6 11.5 6 12C6 12.5 16 18 16 18C16 18 16 6 16 6Z" fill="#F6F3FC" stroke="#F6F3FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <div className="text-[#F6F3FC] text-lg font-bold">
                {currentSlide + 1} / {tutorialSlides.length}
            </div>
            <button
                onClick={currentSlide === tutorialSlides.length - 1 ? onClose : nextSlide}
            >
                {currentSlide === tutorialSlides.length - 1 ? (
                    <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ filter: 'drop-shadow(0px 4px 0px #623FA5)' }}>
                        <path d="M17 7L7 17M7 7L17 17" stroke="#F6F3FC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                ) : (
                    <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ filter: 'drop-shadow(0px 4px 0px #623FA5)' }}>
                        <path d="M8 6C8 6 18 11.5 18 12C18 12.5 8 18 8 18C8 18 8 6 8 6Z" fill="#F6F3FC" stroke="#F6F3FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                )}
            </button>
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Как играть"
            hasCloseButton={true}
            footer={renderNavigation()}
            useFullHeight={true}
        >
            <div className="flex-1 flex flex-col">
                {renderSlideContent(tutorialSlides[currentSlide])}
            </div>
        </Modal>
    );
};

export default TutorialModal;
