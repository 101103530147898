import React from "react";
import "./index.css";
import "./styles/main.scss";
import App from "./App";
import { StoreProvider } from "./store/StoreProvider";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
);