import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import icons from "../assets/icons";
import Icon from "./Icon";

const BottomNavigation = () => {
  const location = useLocation();
  const navItems = [
    { path: "/", label: "Главная", icon: icons.Home, activeIcon: icons.HomeFill },
    { path: "/friends", label: "Друзья", icon: icons.Friends, activeIcon: icons.FriendsFill },
    { path: "/shop", label: "Магазин", icon: icons.Shop, activeIcon: icons.ShopFill },
    { path: "/leaderboard", label: "Лидеры", icon: icons.Stats, activeIcon: icons.StatsFill },
  ];

  return (
    <nav
      className="fixed bottom-0 left-0 right-0 bg-[#623FA5] border-t border-[#FFFFFF40] flex justify-around py-2 px-1"
    >
      {navItems.map((item, index) => (
        <NavLink
          to={item.path}
          key={item.path}
          className={({ isActive }) =>
            `flex flex-col items-center font-montserrat text-xs uppercase font-bold mb-4 ${
              isActive ? "text-[#F6F3FC]" : "text-[#FFFFFF] opacity-50"
            } relative`
          }
          style={{ flex: 1 }}
        >
          {/* Передаем isActive правильно */}
          {({ isActive }) => (
            <>
              <Icon
                isActive={isActive}
                icon={item.icon}
                activeIcon={item.activeIcon}
                className="w-6 h-6 mb-1"
              />
              {item.label}
              {index < navItems.length - 1 && (
                <span className="absolute right-0 top-1/2 h-9 border-r border-[#F6F3FC] transform -translate-y-1/2 opacity-20"></span>
              )}
            </>
          )}
        </NavLink>
      ))}
    </nav>
  );
};

export default BottomNavigation;
