import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // Можно добавить компонент загрузки
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Перенаправляем на главную страницу, сохраняя целевой URL
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
