// GamePage.jsx
import React, { useEffect } from "react";
import BottomNavigation from "../components/BottomNavigation";
import TicketCounter from "../components/TicketCounter";
import GameActions from "../components/GameActions";
import gameStore from "../store/store";
import axios from 'axios';
import { observer } from "mobx-react-lite";

const GamePage = observer(() => {
  const { loginStatus } = gameStore;

  useEffect(() => {
    gameStore.fetchProfile();
  }, []);

  return (
    <div className="h-screen flex flex-col">
      {!loginStatus && (
        <div className="flex items-center justify-center text-[#F6F3FC] uppercase font-bold h-screen w-11/12 max-w-md mx-auto text-2xl text-center">
          Не удалось авторизоваться
        </div>
      )}
      {loginStatus && (
        <div className="flex-1 overflow-auto" style={{ paddingTop: "112px", paddingBottom: "85px" }}>
          <div className="flex flex-col items-center h-full">
            {/* Верхний блок тикетов */}
            <TicketCounter />

            {/* Блок действий */}
            <div className="w-full">
              <div className="max-w-md mx-auto">
                <GameActions />
              </div>
            </div>

            {/* Нижняя навигация */}
            <BottomNavigation />
          </div>
        </div>
      )}
    </div>
  );
});

export default GamePage;
