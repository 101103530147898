import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import icons from "../assets/icons";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import useWindowSize from "../hooks/useWindowSize";
import backCard from "../assets/img/backCard.png";

const Card = observer(({ card, columnIndex, isDraggable, cardsToDrag = [], style, index, className }) => {
  const { width } = useWindowSize();
  const lastTapRef = React.useRef(0);
  const tapTimeoutRef = React.useRef(null);

  // Определяем величину смещения в зависимости от размера экрана
  let overlap;
  if (width >= 1024) { // Большие экраны (lg)
    overlap = 30; // пикселей
  } else if (width >= 768) { // Средние экраны (md)
    overlap = 25;
  } else { // Маленькие экраны (sm)
    overlap = 20;
  }

  const topOffset = index * overlap;

  const [{ isDragging }, dragRef] = useDrag({
    type: "CARD",
    item: () => {
      gameStore.setDraggedCards(cardsToDrag);
      return { cards: cardsToDrag, fromColumnIndex: columnIndex };
    },
    canDrag: () => {
      if (!isDraggable || !card.isFaceUp || gameStore.isAutoCompleting) return false;
      return true;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      gameStore.clearDraggedCards();
    },
  });

  // Определяем цвет для масти
  const fillColor = card.color === "black" ? "#493075" : "#F21E84";

  // Преобразуем значение карты в букву, если нужно
  const getCardValue = (value) => {
    const faceCards = { 11: "J", 12: "Q", 13: "K", 1: "A" };
    return faceCards[value] || value;
  };

  // Получаем иконку масти
  const Icon = icons[card.suit.charAt(0).toUpperCase() + card.suit.slice(1)];

  const cardStyle = {
    animation: card.isShaking ? 'shake 0.3s ease-in-out' : 'none',
    cursor: isDraggable ? "grab" : "default",
    opacity: isDragging ? 0.5 : 1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    top: `${topOffset}px`,
    width: "50px",
    height: "75px",
    zIndex: index,
    borderRadius: "4px",
    ...style,
  };

  useEffect(() => {
    if (card.isShaking) {
      const timer = setTimeout(() => {
        gameStore.setCardShaking(card.id, false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [card.isShaking, card.id, gameStore]);

  return (
    <div
      ref={isDraggable ? dragRef : null}
      style={cardStyle}
      className={`absolute shadow-lg card ${!card.isFaceUp ? 'flipped' : ''} 
                  sm:w-16 md:w-20 lg:w-24 h-16 sm:h-24 md:h-28 lg:h-32
                  mx-auto
                  ${isDragging ? "hidden" : ""}`}
      onAnimationEnd={() => {
        if (card.isShaking) {
          gameStore.setCardShaking(card.id, false);
        }
      }}
      onDoubleClick={() => {
        // Проверяем, является ли карта последней в колонке
        if (isDraggable && cardsToDrag.length === 1 && cardsToDrag[0].id === card.id) {
          gameStore.handleDoubleClick(card);
        }
      }}
      onTouchEnd={(e) => {
        // Проверяем, является ли карта последней в колонке
        if (!isDraggable || cardsToDrag.length !== 1 || cardsToDrag[0].id !== card.id) return;
        
        const now = Date.now();
        const DOUBLE_TAP_DELAY = 300; // ms

        if (now - lastTapRef.current < DOUBLE_TAP_DELAY) {
          // Двойное нажатие обнаружено
          e.preventDefault();
          clearTimeout(tapTimeoutRef.current);
          gameStore.handleDoubleClick(card);
        } else {
          // Одиночное нажатие
          tapTimeoutRef.current = setTimeout(() => {
            // Обработка одиночного нажатия, если нужно
          }, DOUBLE_TAP_DELAY);
        }
        lastTapRef.current = now;
      }}
    >
      <div className="card-inner">
        <div className="card-front flex items-center justify-center">
          <div 
            className="relative w-full h-full bg-white rounded" 
            style={{ 
              border: "0.5px solid #623FA5",
              borderRadius: "4px",
              width: "calc(100% + 1px)",
              height: "calc(100% + 1px)",
            }}
          >
            <div
              className="absolute top-0 left-1 right-1 flex items-center justify-between gap-1"
              style={{ color: fillColor }}
            >
              <span className="font-bold float-r">{getCardValue(card.value)}</span>
              <Icon className="w-4 h-4" style={{ fill: fillColor }} />
            </div>

            <Icon
              className="absolute inset-x-0 bottom-2 w-8 h-8 mx-auto"
              style={{ fill: fillColor }}
            />
          </div>
        </div>
        <div className="card-back flex items-center justify-center">
          <div 
            className="relative w-full h-full rounded" 
            style={{
              background: "linear-gradient(180deg, #7854BC 0%, #623FA5 100%)",
              border: "1.5px solid #FFFFFF",
              borderRadius: "4px",
            }}
          >
            <img
              src={backCard}
              alt="Card Back"
              className="w-full h-full rounded pointer-events-none"
              style={{ borderRadius: "4px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Card;
