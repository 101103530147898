// Column.jsx
import React from "react";
import { useDrop } from "react-dnd";
import Card from "./Card";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import soundService from "../services/soundService";

const Column = observer(({ cards, columnIndex, moveCards }) => {
  const { draggedCards } = gameStore;

  const [, dropRef] = useDrop({
    accept: "CARD",
    drop: (item) => {
      if (item.fromColumnIndex !== columnIndex) {
        moveCards(item.fromColumnIndex, columnIndex, item.cards);
      }
    },
    canDrop: (item) => {
      const targetCard = cards[cards.length - 1];
      const movingCard = item.cards[0];

      if (!targetCard) {
        return movingCard.value === 13;
      }

      return (
        targetCard.color !== movingCard.color &&
        targetCard.value === movingCard.value + 1
      );
    },
  });

  return (
    <div
      ref={dropRef}
      className="relative flex flex-col items-center w-12 sm:w-24 md:w-28 lg:w-32"
      style={{ minHeight: "1000px", minWidth: "52px", flexGrow: 1 }} // Минимальная высота для размещения карт
    >
      {cards.map((card, index) => {
        // Проверяем, находится ли карта в списке перетаскиваемых
        const isDragged = draggedCards.some((c) => c.id === card.id);
        if (isDragged) {
          return (
            <Card
              key={card.id}
              card={card}
              columnIndex={columnIndex}
              isDraggable={false}
              cardsToDrag={[]}
              style={{ visibility: "hidden" }} // Скрываем карту
              index={index} // Передаём индекс
            />
          );
        }

        // Определяем, должна ли карта быть draggable
        const isDraggable = card.isFaceUp;

        // Разрешаем перетаскивание любой перевёрнутой вверх карты
        const shouldDrag = isDraggable;

        // Вычисляем cardsToDrag начиная с текущей карты до конца
        const cardsToDrag = shouldDrag ? cards.slice(index) : [];

        return (
          <Card
            key={card.id}
            card={card}
            columnIndex={columnIndex}
            isDraggable={shouldDrag}
            cardsToDrag={cardsToDrag}
            index={index}
          />
        );
      })}
    </div>
  );
});

export default Column;
